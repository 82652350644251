import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = ({ data, location }) => {
  const { t } = useTranslation("errors");
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={t("404 Not Found")} />
      <h1>{t("404 Not Found")}</h1>
      <p>
        {t("Du hast eine Seite aufgerufen die nicht gefunden werden konnte.")}
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      ...SiteMetadata
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...I18n
        }
      }
    }
  }
`;
